import React from "react";
import { useTranslation } from "react-i18next";

export const Contact = () => {
  const { t, i18n } = useTranslation();
  const whatsappNumber = i18n.language === 'tr' || i18n.language === 'de' ? "+905421600007" : "+905421600007";
  const whatsappLink = `https://wa.me/${whatsappNumber}`;

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t('Contact.title')}</h2>
                <p>{t('Contact.description')}</p>
              </div>
              <div className="form-group">
                <a href={whatsappLink} className="btn btn-custom btn-lg" target="_blank" rel="noopener noreferrer">
                  {t('Contact.form.whatsapp')}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{t('Contact.info.title')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('Contact.info.address')}
                </span>
                {t('Contact.info.addressDetails')}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {t('Contact.info.phone')}
                </span>{" "}
                {whatsappNumber}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {t('Contact.info.email')}
                </span>{" "}
                {t('Contact.info.emailDetails')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 MySideVIP Transfer. {t('Contact.footer')}
          </p>
        </div>
      </div>
    </div>
  );
};
