import React from "react";
import { useTranslation } from "react-i18next";

export const Team = () => {
  const { t } = useTranslation();
  const team = t('Team.members', { returnObjects: true });

  if (!team) {
    return <div>{t('Loading')}</div>;
  }

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>{t('Team.title')}</h2>
          <p>{t('Team.description')}</p>
        </div>
        <div id="row">
          {team.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
              <div className="thumbnail"  >
                <img  src={d.img} alt={d.name} className="team-photo" />
                <div className="caption">
                  <h4>{d.name}</h4>
                  <p>{d.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
