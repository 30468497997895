import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const Navigation = () => {
  const { i18n, t } = useTranslation();

  // Tarayıcı diline göre varsayılan dili ayarlama
  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes('tr')) {
      i18n.changeLanguage('tr');
    } else if (userLang.includes('ru')) {
      i18n.changeLanguage('ru');
    } else if (userLang.includes('de')) {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            MySide<span className="vip">VIP</span>
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li>
            <a href="#features" className="page-scroll">
                {t('Features.title')}
              </a>
              

            </li>
            <li>
            <a href="#about" className="page-scroll">
                {t('About.title')}
              </a>

            </li>
            <li>
              <a href="#services" className="page-scroll">
                {t('Services.title')}
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                {t('Team.title')}
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                {t('Contact.title')}
              </a>
            </li>
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t('Language')} <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="#" onClick={() => changeLanguage('tr')}>
                    Türkçe
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => changeLanguage('en')}>
                    English
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => changeLanguage('ru')}>
                    Русский
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => changeLanguage('de')}>
                    Deutsch
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
