import { Image } from "./image";
import React from "react";
import { useTranslation } from "react-i18next";

export const Gallery = () => {
  const { t, i18n } = useTranslation();
  
  const galleryTitle = i18n.language === 'tr' ? "Galeri" : 
                       i18n.language === 'de' ? "Galerie" : 
                       i18n.language === 'ru' ? "Галерея" : "Gallery";
  
  const galleryDescription = i18n.language === 'tr' ? "Güzel anlarımızı ve mekanlarımızı keşfedin." :
                             i18n.language === 'de' ? "Erkunden Sie unsere Galerie schöner Momente und Orte." :
                             i18n.language === 'ru' ? "Исследуйте нашу галерею красивых моментов и мест." :
                             "Explore our gallery of beautiful moments and places.";
  
  const galleryItems = [
    {
      title: "Caravelle Inside",
      largeImage: "img/portfolio/01-large.jpg",
      smallImage: "img/portfolio/01-small.jpg"
    },
    {
      title: "Caravelle Outside",
      largeImage: "img/portfolio/02-large.jpg",
      smallImage: "img/portfolio/02-small.jpg"
    },
    {
      title: "Vito Inside",
      largeImage: "img/portfolio/03-large.jpg",
      smallImage: "img/portfolio/03-small.jpg"
    },
    {
      title: "Vito Outside",
      largeImage: "img/portfolio/04-large.jpg",
      smallImage: "img/portfolio/04-small.jpg"
    },
    {
      title: "Maybach Inside",
      largeImage: "img/portfolio/05-large.jpg",
      smallImage: "img/portfolio/05-small.jpg"
    },
    {
      title: "Maybach Outside",
      largeImage: "img/portfolio/06-large.jpg",
      smallImage: "img/portfolio/06-small.jpg"
    },
  ];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{galleryTitle}</h2>
          <p>{galleryDescription}</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {galleryItems.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
